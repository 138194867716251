import React, { FunctionComponent, useEffect } from 'react';
import { DependencyContainer } from '../../../DependencyContainer';
import { Scenario, ScenarioLevels, ScenarioStatuses } from '../types';
import { useFetchData } from '../../common/hooks/useFetchData';
import PendingContent from '../../../components/PendingContent/PendingContent';
import { useParams } from 'react-router-dom';
import Button, { ButtonStyles } from '../../../components/Button/Button';
import LockedScenarioMessage from '../components/LockedScenarioMessage';
import ScenarioMediaSection from '../components/ScenarioMedia';
import SpinnerLoader from '../../../components/SpinnerLoader/SpinerLoader';
import { Helmet } from 'react-helmet';
import { useRedirectToRoute } from '../../common/hooks/useRedirectToRoute';
import { AppRoutes } from '../../../config/routes';
import { useKeycloak } from '@react-keycloak/web';
import AudioPlayer, {
  AudioPlayerStyles,
} from '../../../components/AudioPlayer/AudioPlayer';
import { getSlackChannelForTenant } from '../../../config/slack';
import { useUser } from '../../common/hooks/useUser';
import { GamePlay, gamePlayAtom, GameStatus } from '../../../state';
import { useSetRecoilState } from 'recoil';
import Joyride, { Step } from 'react-joyride';
import { usePersistentState } from '../../../persistent-state/persistent-state';
import { joyrideStyles } from '../../../config/config';
import { useFeatureFlag } from '../../common/hooks/useFeatureFlag';
import { Features } from '../../../config/features';
import { WordpressContent, WordpressLevel } from '../../content/types';
import { useBearerToken } from '../../common/hooks/useBearerToken';
import { ReadyToPlay } from '../../reports/types';
import { AxiosError } from 'axios';
import ScenarioIntroductionInfraMessage from '../components/ScenarioIntroductionInfraMessage';
import LobbyCard from '../components/Lobby/lobbyCard';


const { scenariosService, contentService } = DependencyContainer.getInstance();

const addSpacingToButtons = (override?: string) => (current: ButtonStyles) => ({
  ...current,
  root: `${current.root} ScenariosContainer__action ${override}`,
});

const ScenarioLevelContainer: FunctionComponent = () => {
  const goToRoute = useRedirectToRoute();
  const { keycloak } = useKeycloak();
  const { id, level } = useParams();
  const user = useUser();
  const setGamePlay = useSetRecoilState<GamePlay>(gamePlayAtom);
  const isOnBoardingAvailable = useFeatureFlag(Features.OnBoarding);
  const [loading, scenario, error] = useFetchData<Scenario, Error>(() => {
    if (!id) {
      return Promise.reject();
    }
    return scenariosService.getScenario(id);
  });
  const token = useBearerToken();

  const [levelLoading, levelContent, levelError] = useFetchData<
    WordpressContent<WordpressLevel>,
    Error
  >(
    () => {
      return contentService.getLevelBySlug(level!);
    },
    undefined,
    [scenario, level],
    () => !level,
  );

  const startScenario = () => {
    if (!scenario || !levelContent || !keycloak.tokenParsed) {
      return;
    }
    const levelId = levelContent.acf.level_id;
    scenariosService.setPlayer(user, scenario.id, levelId);
    setGamePlay({
      level: levelId.toString(),
      scenario: scenario.id,
      status: GameStatus.Ready,
      gameRemainingSeconds: 0,
    });
    setTimeout(() => {
      goToRoute(AppRoutes.PlayScenario, {
        scenarioId: scenario.id,
        levelSlug: levelContent.slug,
      });
    }, 400);
  }

  const goToLevelSelection = () => {
    goToRoute(AppRoutes.ScenarioLevels, {
      id,
    });
  };

  const getThrottleConditions = (): boolean => {
    return !token;
  };

  const [loadingReadyToPlay, ReadyToPlay, ReadyToPlayError] = useFetchData<
    ReadyToPlay,
    AxiosError
  >(
    () => {
      return scenariosService.getReadyToPlay(token!);
    },
    undefined,
    [token],
    () => getThrottleConditions(),
  );

  useEffect(() => {
    const userEmail = keycloak.tokenParsed?.email;
    if (
      !userEmail ||
      !user.tenant ||
      level !== ScenarioLevels.Level1.toString()
    ) {
      return;
    }
    const slackChannel = getSlackChannelForTenant(user.tenant);
    if (!slackChannel) {
      return;
    }
    scenariosService.subscribeToSlackChannel(userEmail, slackChannel);
  }, [keycloak.tokenParsed, user.tenant, level]);
  const [tourRan] = usePersistentState('tourRan');

  if (scenario?.status === ScenarioStatuses.Locked) {
    return <LockedScenarioMessage />;
  }

  const renderTitle = (): string => {
    const parts = `${scenario?.title} -  ${levelContent?.title.rendered}`;
    return parts;
  };

  // for lobby
  const hasLobby = levelContent?.acf.is_lobby_available;

  const audio = levelContent?.acf.audioUrl;
  const steps: Step[] = [
    {
      target: '.ScenariosContainer__description',
      content:
        'Please get familiar with these information. Here you can find your learning objective, story and what should you expect.',
    },
    {
      target: '.ScenariosContainer__actions',
      content:
        'You can click on green button to start the game play of the level.',
    },
  ];
  return (
    <div className="ScenariosContainer">
      <Helmet>
        <title> UptimeLabs </title>
      </Helmet>
      {!loading && scenario && (
        <Helmet>
          <title>{renderTitle()} - UptimeLabs</title>
        </Helmet>
      )}
      <PendingContent
        loading={loading || levelLoading}
        isError={Boolean(error) || Boolean(levelError)}
        hideContent
        message={error?.message}
        loader={SpinnerLoader}
      >
        <div className="ScenariosContainer__scenario">
          <div className="ScenariosContainer__content">
            {isOnBoardingAvailable() && (
              <Joyride
                steps={steps}
                stepIndex={0}
                run={!tourRan}
                styles={joyrideStyles}
              />
            )}
            {audio && (
              <AudioPlayer
                source={audio}
                classes={(current: AudioPlayerStyles) => ({
                  ...current,
                  root: `${current.root} ScenariosContainer__audio-player`,
                })}
              />
            )}

            <h1 className="ScenariosContainer__title">{renderTitle()}</h1>
            {levelContent?.content.rendered && (
              <div className="ScenariosContainer__levelIntroduction">
                <div
                  className="ScenariosContainer__description"
                  dangerouslySetInnerHTML={{
                    __html: levelContent.content.rendered,
                  }}
                />
                {hasLobby && scenario && (
                  <LobbyCard
                    scenario={scenario}
                    level={levelContent}
                    user={user}
                    keycloak={keycloak}
                    readyToPlay={Boolean(ReadyToPlay)}
                    isJoiningExistingLobby={false}
                    joiningLobby={null}
                  />
                )}
              </div>
            )}

            {
              <ScenarioIntroductionInfraMessage
                ReadyToPlayLoading={loadingReadyToPlay}
                ReadyToPlay={Boolean(ReadyToPlay)}
                ReadyToPlayError={ReadyToPlayError}
              />
            }

            <div className="ScenariosContainer__actions">
              <Button
                classes={addSpacingToButtons()}
                onClick={() => goToLevelSelection()}
              >
                Back to all drills
              </Button>
              {hasLobby ? null : (
                <Button
                  classes={addSpacingToButtons(
                    'ScenariosContainer__action--pulse-orange',
                  )}
                  variant="superprimary"
                  onClick={() => startScenario()}
                  disabled={Boolean(!ReadyToPlay)}
                >
                  Start drill
                </Button>
              )}
            </div>
          </div>
          {scenario?.media && (
            <ScenarioMediaSection
              title={scenario.title}
              media={scenario.media}
            />
          )}
        </div>
      </PendingContent>
    </div>
  );
};

export default ScenarioLevelContainer;
